import React from 'react';
import dayjs from 'dayjs';
import cx from 'classnames';
import { isEmpty, isUndefined } from 'lodash-es';
import constants from 'appConstants';
import { toJpeg } from 'html-to-image';
import JsPDF from 'jspdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCircleXmark, faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import Tooltip from '@beewise/tooltip';

const EVENT_STATUSES = {
    SUCCESSFULLY_FILLED: 'SUCCESSFULLY_FILLED',
    ALREADY_FULL: 'ALREADY_FULL',
};

const WEEKS_IN_MONTH = 4;
const DAYS_IN_WEEK = 7;

export const SUCCESSFULLY_FILLED_STATUS = 'SUCCESSFULLY_FILLED';
export const EXPANDED_YARD_HEIGHT = 190;
export const RED_SYRUP_LEVEL = 5;
export const SHIFT = 'Shift';
export const GRAPHS_LEVELS = {
    brood: {
        medium: 8,
        low: 4,
    },
    empty: {
        medium: 5,
        low: 2,
    },
    honey: {
        medium: 5,
        low: 2,
    },
    population: {
        medium: 10,
        low: 4,
    },
};
export const FILTER_AVERAGE_OPTIONS = [
    { value: 'gt', label: 'Above' },
    { value: 'lt', label: 'Below' },
];
export const averagesValues = {
    EMPTY_FRAMES_AVERAGE: 'emptyFramesAverage',
    BEE_FRAMES_AVERAGE: 'beeFramesAverage',
    HONEY_FRAMES_AVERAGE: 'honeyFramesAverage',
    BROOD_FRAMES_AVERAGE: 'broodFramesAverage',
};

export const actionsValues = {
    TRANSPORT_ACTION: constants.MODE.TRANSPORTATION,
    VISIT_ACTION: constants.MODE.VISIT,
    FEED_ACTION: 'feed',
};

export const getRowSize = () => EXPANDED_YARD_HEIGHT;

export const TRENDS = {
    POSITIVE: 'positive',
    NEGATIVE: 'negative',
};

export const SORT_WORKSPACE_OPTIONS = [
    { value: 'location-north-to-south', label: 'Location - North to South' },
    { value: 'location-south-to-north', label: 'Location - South to North' },
];

export const ACTION_START = 'start';
export const ACTION_END = 'end';

export const NO_YARD_ID = 'Unknown Yard';
export const NO_RANCH_ID = 'Unknown Ranch';
const SYRUP_CAPACITY = 20;

const AMERICAN_LETTER_SIZE_SCALE = 1;
const AMERICAN_LETTER_SIZE = {
    width: 21.6 * AMERICAN_LETTER_SIZE_SCALE,
    height: 27.9 * AMERICAN_LETTER_SIZE_SCALE,
};
const PIXEL_TO_CM = 0.0264583333;

export const WEEK_LABELS = {
    THIS_WEEK: 'This week:',
    LAST_WEEK: 'Last week:',
    LAST_MONTH: 'Last month:',
};

const DATE_FORMAT_FOR_TOOLTIP = 'D MMM';
const ISO_DATE_FORMAT = 'YYYY-MM-DD';

const LAST_FIVE_WEEKS = 5;

export const getTimeOptions = () => {
    const today = dayjs();

    const startOfThisWeek = today.startOf('week').format('DD MMM');
    const endOfThisWeek = today.endOf('week').format('DD MMM');

    const startOfLastWeek = today.subtract(1, 'week').startOf('week').format('DD MMM');
    const endOfLastWeek = today.subtract(1, 'week').endOf('week').format('DD MMM');

    const currentMonth = today.format('MMM YYYY');
    const lastMonth = today.subtract(1, 'month').format('MMM');

    const startOfWeek = today.startOf('week');
    const daysFromStartOfWeek = today.diff(startOfWeek, 'day') + 1;
    const daysInLastWeek = 7;
    const startOfFiveWeeksAgo = today.subtract(LAST_FIVE_WEEKS, 'week').startOf('week');
    const daysFromStartOfFiveWeeksAgo = today.diff(startOfFiveWeeksAgo, 'day') + 1;

    return [
        {
            label: WEEK_LABELS.THIS_WEEK,
            extraLabelData: `${startOfThisWeek} - ${endOfThisWeek}`,
            value: daysFromStartOfWeek,
        },
        {
            label: WEEK_LABELS.LAST_WEEK,
            extraLabelData: `${startOfLastWeek} - ${endOfLastWeek}`,
            value: daysInLastWeek + daysFromStartOfWeek,
        },
        {
            label: WEEK_LABELS.LAST_MONTH,
            extraLabelData: `${lastMonth} - ${currentMonth}`,
            value: daysFromStartOfFiveWeeksAgo,
        },
    ];
};

const processAverages = (bhome, acc) => {
    const updateAcc = (value, levels, accArray) => {
        if (value > levels.medium) {
            accArray[2] += 1;
        } else if (value > levels.low) {
            accArray[1] += 1;
        } else {
            accArray[0] += 1;
        }
    };
    updateAcc(bhome.averageBroodWithin2Weeks, GRAPHS_LEVELS.brood, acc.averageBrood);
    updateAcc(bhome.averageEmptyWithin2Weeks, GRAPHS_LEVELS.empty, acc.averageEmpty);
    updateAcc(bhome.averageHoneyWithin2Weeks, GRAPHS_LEVELS.honey, acc.averageHoney);
    updateAcc(bhome.averagePopulationWithin2Weeks, GRAPHS_LEVELS.population, acc.averagePopulation);
};

const getDefaultAccumulator = () => ({
    lastFeedTime: null,
    syrupRequired: 0,
    syrupLow: 0,
    totalSyrup: 0,
    larvaePresence: 0,
    totalHives: 0,
    totalBrood: 0,
    totalEmpty: 0,
    totalHoney: 0,
    totalPopulation: 0,
    totalThermalAssessmentDeadHives: 0,
    averagePopulation: [0, 0, 0],
    averageBrood: [0, 0, 0],
    averageEmpty: [0, 0, 0],
    averageHoney: [0, 0, 0],
    coordsState: null,
    totalSyrupVolume: 0,
    isFailedFeedEventExistsWithinWeek: false,
});

const getTodaysData = data => {
    const recentCreatedAt = Math.max(...data.map(({ createdAt }) => createdAt));
    const recentData = data.filter(({ createdAt }) => recentCreatedAt === createdAt);

    return recentData.reduce((acc, bhome) => {
        if (bhome.lastFeedTime && bhome.lastFeedTime > acc.lastFeedTime) {
            acc.lastFeedTime = bhome.lastFeedTime;
        }
        if (bhome.totalAmountOfBroodHives) {
            acc.larvaePresence += bhome.totalAmountOfBroodHives;
        }
        if (bhome.totalThermalAssessmentDeadHives) {
            acc.totalThermalAssessmentDeadHives += bhome.totalThermalAssessmentDeadHives;
        }
        if (bhome.isFailedFeedEventExistsWithinWeek) {
            acc.isFailedFeedEventExistsWithinWeek = true;
        }
        processAverages(bhome, acc);
        acc.syrupRequired += SYRUP_CAPACITY * ((100 - (bhome.syrupLevel ?? 0)) / 100);
        acc.syrupLow += (bhome.syrupLevel ?? 0) < 20 ? 1 : 0;
        acc.totalSyrup += Number.isNaN(bhome.syrupLevel) ? 0 : Number(bhome.syrupLevel);
        acc.totalBrood += bhome.averageBroodWithin2Weeks;
        acc.totalEmpty += bhome.averageEmptyWithin2Weeks;
        acc.totalHoney += bhome.averageHoneyWithin2Weeks;
        acc.totalPopulation += bhome.averagePopulationWithin2Weeks;
        acc.totalHives += bhome.markedHives?.length ?? 0;
        acc.totalSyrupVolume += SYRUP_CAPACITY;
        acc.coordsState = bhome.coordsState;
        return acc;
    }, getDefaultAccumulator());
};

const getYesterdaysData = (yesterdaysData, key) => {
    let yesterdayResultData = {
        yesterdayTotalHives: 0,
        yesterdayTotalBrood: 0,
        yesterdayTotalEmpty: 0,
        yesterdayTotalHoney: 0,
        yesterdayTotalPopulation: 0,
    };

    if (!isEmpty(yesterdaysData[key])) {
        yesterdayResultData = yesterdaysData[key].reduce((acc, bhome) => {
            if (bhome) {
                acc.yesterdayTotalBrood += bhome.averageBroodWithin2Weeks;
                acc.yesterdayTotalEmpty += bhome.averageEmptyWithin2Weeks;
                acc.yesterdayTotalHoney += bhome.averageHoneyWithin2Weeks;
                acc.yesterdayTotalPopulation += bhome.averagePopulationWithin2Weeks;
                acc.yesterdayTotalHives += bhome.markedHives?.length ?? 0;
            }

            return acc;
        }, yesterdayResultData);
    }

    return yesterdayResultData;
};

const checkFilterTrends = ({ totalValue, data, yesterdayTotalData, yesterdaysData, emptyFramesAverage }) => {
    const averageValue = totalValue ? Number(totalValue / (data?.length ?? 1)).toFixed(1) : 0;

    if (!averageValue) {
        return;
    }

    const yesterdayValue = yesterdayTotalData
        ? Number(yesterdayTotalData / (yesterdaysData?.length ?? 1)).toFixed(1)
        : 0;

    let populationTrend;

    if (emptyFramesAverage) {
        if (averageValue < yesterdayValue) {
            populationTrend = TRENDS.POSITIVE;
        } else if (averageValue > yesterdayValue) {
            populationTrend = TRENDS.NEGATIVE;
        }
    } else if (averageValue > yesterdayValue) {
        populationTrend = TRENDS.POSITIVE;
    } else if (averageValue < yesterdayValue) {
        populationTrend = TRENDS.NEGATIVE;
    }

    return populationTrend;
};

const filterActions = (filter, yard, actionType) => {
    if (!filter[actionType]) return true;

    const oneWeekAgo = dayjs().subtract(1, 'week');
    const conditions = [];

    if (filter[actionType].activeNow && filter[actionType].pastWeek) {
        const activeActions = yard.yardActions.filter(
            action => action.action === actionType && (!action.end_time || dayjs(action.end_time).isAfter(oneWeekAgo))
        );
        if (!activeActions.length) conditions.push(true);
    } else if (filter[actionType].activeNow && filter[actionType].moreThanAWeek) {
        const activeActions = yard.yardActions.filter(
            action => action.action === actionType && (!action.end_time || dayjs(action.end_time).isBefore(oneWeekAgo))
        );
        if (!activeActions.length) conditions.push(true);
    } else if (filter[actionType].pastWeek && filter[actionType].moreThanAWeek) {
        const activeActions = yard.yardActions.filter(
            action =>
                action.action === actionType &&
                (dayjs(action.end_time).isAfter(oneWeekAgo) || dayjs(action.end_time).isBefore(oneWeekAgo))
        );
        if (!activeActions.length) conditions.push(true);
    } else if (filter[actionType].activeNow) {
        const activeActions = yard.yardActions.filter(action => action.action === actionType && !action.end_time);
        if (!activeActions.length) conditions.push(true);
    } else if (filter[actionType].pastWeek) {
        const pastWeekActions = yard.yardActions.filter(
            action => action.action === actionType && dayjs(action.end_time).isAfter(oneWeekAgo)
        );
        if (!pastWeekActions.length) conditions.push(true);
    } else if (filter[actionType].moreThanAWeek) {
        const moreThanAWeekActions = yard.yardActions.filter(
            action => action.action === actionType && dayjs(action.end_time).isBefore(oneWeekAgo)
        );
        if (!moreThanAWeekActions.length) conditions.push(true);
    }

    return !conditions.length;
};

export const filterYards = filter => yard => {
    let passedFilter = true;

    if (
        passedFilter &&
        filter.regions?.length &&
        (!yard.regionId || !filter.regions.includes(yard.regionId)) &&
        (!yard.coordsState || !filter.regions.includes(yard.coordsState))
    ) {
        return false;
    }

    if (passedFilter && filter.syrupTank.min >= 0 && filter.syrupTank.max) {
        const averageSyrup = (yard.totalSyrup || 0) / (yard.data?.length || 1);
        passedFilter = averageSyrup >= filter.syrupTank.min && averageSyrup <= filter.syrupTank.max;
    }

    if (passedFilter && filter.larvae.min >= 0 && filter.larvae.max) {
        const larvaePercentage = Number(((yard.larvaePresence || 0) / (yard.totalHives || 1)) * 100).toFixed(0);
        passedFilter = larvaePercentage >= filter.larvae.min && larvaePercentage <= filter.larvae.max;
    }

    if (passedFilter && filter.recentWeekFeeding) {
        passedFilter = yard.lastFeedTime && dayjs().diff(dayjs(yard.lastFeedTime), 'day', true) < 7;
    }

    if (passedFilter && filter.moreThanAWeekFeeding) {
        passedFilter = yard.lastFeedTime && dayjs().diff(dayjs(yard.lastFeedTime), 'day', true) >= 7;
    }

    if (passedFilter && filter[averagesValues.EMPTY_FRAMES_AVERAGE].value) {
        const averageEmpty = yard.totalEmpty ? Number(yard.totalEmpty / (yard.data?.length ?? 1)) : 0;
        passedFilter =
            filter[averagesValues.EMPTY_FRAMES_AVERAGE].option === FILTER_AVERAGE_OPTIONS[0].value
                ? averageEmpty > filter[averagesValues.EMPTY_FRAMES_AVERAGE].value
                : averageEmpty < filter[averagesValues.EMPTY_FRAMES_AVERAGE].value;
    }

    if (passedFilter && filter[averagesValues.BEE_FRAMES_AVERAGE].value) {
        const averagePopulation = yard.totalPopulation ? Number(yard.totalPopulation / (yard.data?.length ?? 1)) : 0;
        passedFilter =
            filter[averagesValues.BEE_FRAMES_AVERAGE].option === FILTER_AVERAGE_OPTIONS[0].value
                ? averagePopulation > filter[averagesValues.BEE_FRAMES_AVERAGE].value
                : averagePopulation < filter[averagesValues.BEE_FRAMES_AVERAGE].value;
    }

    if (passedFilter && filter[averagesValues.HONEY_FRAMES_AVERAGE].value) {
        const averageHoney = yard.totalHoney ? Number(yard.totalHoney / (yard.data?.length ?? 1)) : 0;
        passedFilter =
            filter[averagesValues.HONEY_FRAMES_AVERAGE].option === FILTER_AVERAGE_OPTIONS[0].value
                ? averageHoney > filter[averagesValues.HONEY_FRAMES_AVERAGE].value
                : averageHoney < filter[averagesValues.HONEY_FRAMES_AVERAGE].value;
    }

    if (passedFilter && filter[averagesValues.BROOD_FRAMES_AVERAGE].value) {
        const averageBrood = yard.totalBrood ? Number(yard.totalBrood / (yard.data?.length ?? 1)) : 0;
        passedFilter =
            filter[averagesValues.BROOD_FRAMES_AVERAGE].option === FILTER_AVERAGE_OPTIONS[0].value
                ? averageBrood > filter[averagesValues.BROOD_FRAMES_AVERAGE].value
                : averageBrood < filter[averagesValues.BROOD_FRAMES_AVERAGE].value;
    }

    if (passedFilter && filter[averagesValues.EMPTY_FRAMES_AVERAGE].trend) {
        const trend = checkFilterTrends({
            totalValue: yard.totalEmpty,
            data: yard.data,
            yesterdayTotalData: yard.yesterdayTotalEmpty,
            yesterdaysData: yard.yesterdaysData,
            emptyFramesAverage: true,
        });

        passedFilter = filter[averagesValues.EMPTY_FRAMES_AVERAGE].trend === trend;
    }

    if (passedFilter && filter[averagesValues.BEE_FRAMES_AVERAGE].trend) {
        const trend = checkFilterTrends({
            totalValue: yard.totalPopulation,
            data: yard.data,
            yesterdayTotalData: yard.yesterdayTotalPopulation,
            yesterdaysData: yard.yesterdaysData,
        });

        passedFilter = filter[averagesValues.BEE_FRAMES_AVERAGE].trend === trend;
    }

    if (passedFilter && filter[averagesValues.HONEY_FRAMES_AVERAGE].trend) {
        const trend = checkFilterTrends({
            totalValue: yard.totalHoney,
            data: yard.data,
            yesterdayTotalData: yard.yesterdayTotalHoney,
            yesterdaysData: yard.yesterdaysData,
        });

        passedFilter = filter[averagesValues.HONEY_FRAMES_AVERAGE].trend === trend;
    }

    if (passedFilter && filter[averagesValues.BROOD_FRAMES_AVERAGE].trend) {
        const trend = checkFilterTrends({
            totalValue: yard.totalBrood,
            data: yard.data,
            yesterdayTotalData: yard.yesterdayTotalBrood,
            yesterdaysData: yard.yesterdaysData,
        });

        passedFilter = filter[averagesValues.BROOD_FRAMES_AVERAGE].trend === trend;
    }

    if (passedFilter && actionsValues.TRANSPORT_ACTION) {
        passedFilter = filterActions(filter, yard, actionsValues.TRANSPORT_ACTION);
    }

    if (passedFilter && actionsValues.VISIT_ACTION) {
        passedFilter = filterActions(filter, yard, actionsValues.VISIT_ACTION);
    }

    if (passedFilter && actionsValues.FEED_ACTION) {
        passedFilter = filterActions(filter, yard, actionsValues.FEED_ACTION);
    }

    return passedFilter;
};

export const mapData = (todaysGroupedData, yesterdaysGroupedData, isRanch) => (acc, key) => {
    const resultData = getTodaysData(todaysGroupedData[key]);
    const yesterdayResultData = getYesterdaysData(yesterdaysGroupedData, key);

    if (!key || key === 'null') {
        return acc;
    }

    acc.push({
        id: key,
        name: todaysGroupedData[key][0][isRanch ? 'ranchName' : 'yardName'],
        regionId: todaysGroupedData[key][0][isRanch ? 'regionRanchId' : 'regionYardId'],
        regionName: todaysGroupedData[key][0][isRanch ? 'regionRanchName' : 'regionYardName'],
        ...yesterdayResultData,
        ...resultData,
        data: todaysGroupedData[key],
        yesterdaysData: yesterdaysGroupedData[key],
        isRanch,
    });
    return acc;
};

export const sortYards = (a, b, sort) => {
    if (a.id === NO_YARD_ID || a.id === NO_RANCH_ID) {
        return 1;
    }
    if (b.id === NO_YARD_ID || b.id === NO_RANCH_ID) {
        return -1;
    }
    if (sort === SORT_WORKSPACE_OPTIONS[0].value) {
        return b.lat - a.lat;
    } else if (sort === SORT_WORKSPACE_OPTIONS[1].value) {
        return a.lat - b.lat;
    }
    return a.data?.length > b.data?.length ? -1 : 1;
};

export const getLatestEndTime = data => {
    const visitActions = data?.filter(item => item.action === constants.MODE.VISIT);

    if (!visitActions?.length) {
        return;
    }

    const latestVisit = visitActions.reduce((latest, current) => {
        const currentEndTime = new Date(current.end_time);
        return currentEndTime > new Date(latest.end_time) ? current : latest;
    });

    return latestVisit.end_time;
};

export const getYardData = yard => {
    const larvaePercentage = yard.larvaePresence
        ? Number(((yard.larvaePresence || 0) / (yard.totalHives || 1)) * 100).toFixed(0)
        : '-';
    const lastVisitTime = getLatestEndTime(yard.yardActions);
    const lastVisitTimeFormatted = lastVisitTime ? dayjs(lastVisitTime).format(DATE_FORMAT_FOR_TOOLTIP) : 'N/A';
    const lastFeedTime = yard.lastFeedTime ? dayjs(yard.lastFeedTime).format(DATE_FORMAT_FOR_TOOLTIP) : 'N/A';
    const averageSyrup = yard.totalSyrup ? ((yard.totalSyrup || 0) / (yard.data?.length || 1)).toFixed(0) : '-';
    const population = yard.totalPopulation ? Number(yard.totalPopulation / (yard.data?.length ?? 1)).toFixed(1) : null;
    const yesterdayPopulation = yard.totalPopulation
        ? Number(yard.yesterdayTotalPopulation / (yard.yesterdaysData?.length ?? 1)).toFixed(1)
        : null;
    const brood = yard.totalBrood ? Number(yard.totalBrood / (yard.data?.length ?? 1)).toFixed(1) : null;
    const yesterdayBrood = yard.yesterdayTotalBrood
        ? Number(yard.yesterdayTotalBrood / (yard.yesterdaysData?.length ?? 1)).toFixed(1)
        : null;
    const empty = yard.totalEmpty ? Number(yard.totalEmpty / (yard.data?.length ?? 1)).toFixed(1) : null;
    const yesterdayEmpty = yard.yesterdayTotalEmpty
        ? Number(yard.yesterdayTotalEmpty / (yard.yesterdaysData?.length ?? 1)).toFixed(1)
        : null;
    const honey = yard.totalHoney ? Number(yard.totalHoney / (yard.data?.length ?? 1)).toFixed(1) : null;
    const yesterdayHoney = yard.yesterdayTotalHoney
        ? Number(yard.yesterdayTotalHoney / (yard.yesterdaysData?.length ?? 1)).toFixed(1)
        : null;

    return {
        larvaePercentage,
        lastVisitTimeFormatted,
        lastFeedTime,
        averageSyrup,
        population,
        yesterdayPopulation,
        brood,
        yesterdayBrood,
        empty,
        yesterdayEmpty,
        honey,
        yesterdayHoney,
    };
};

const renderCell = value => (!value ? '-' : value.toFixed(1));

export const summaryColumns = [
    {
        field: 'name',
        headerName: 'Yard',
        minWidth: 240,
        filterable: false,
        sortable: false,
        flex: 1,
    },
    {
        field: 'totalThermalAssessmentDeadHives',
        headerName: 'Dead hives',
        minWidth: 240,
        filterable: false,
        sortable: false,
        flex: 1,
        valueGetter: params => renderCell(params.value),
    },
    {
        field: 'syrupRequired',
        headerName: 'Required syrup (gal)',
        minWidth: 240,
        filterable: false,
        sortable: false,
        flex: 1,
        valueGetter: params => renderCell(params.value),
    },
];

export const exportImage = async ref => {
    if (!ref.current) {
        return;
    }

    try {
        const filter = node => {
            const exclusionClasses = ['remove-from-pdf'];
            return !exclusionClasses.some(classname => node.classList?.contains(classname));
        };
        const dataUrl = await toJpeg(ref.current, { quality: 0.9, cacheBust: true, filter });
        const summaryWidth = ref.current.clientWidth * PIXEL_TO_CM;
        const summaryHeight = ref.current.clientHeight * PIXEL_TO_CM;

        const pdf = new JsPDF('p', 'cm', [AMERICAN_LETTER_SIZE.height, AMERICAN_LETTER_SIZE.width]);

        pdf.addImage(dataUrl, 'PNG', 0, 0, summaryWidth, summaryHeight);

        pdf.save('summary.pdf');
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error exporting image:', error);
    }
};

export const getBhomeIds = (yards, selectedIds, yardId) => {
    if (yardId) {
        const yard = yards.find(yard => yard.id === yardId);

        return yard ? yard.bhomes : [];
    }

    const selectedYards = yards.filter(yard => selectedIds.includes(yard.id));

    return selectedYards.flatMap(yard => yard.bhomes);
};

export const getYardIdsToUpdate = (selectedIds, yardActions, modes) =>
    selectedIds.filter(
        id =>
            !yardActions.some(
                yardAction => yardAction.yard_id === id && !yardAction.end_time && modes.includes(yardAction.action)
            )
    );

export const findYardActionIds = (yardActions, yardId) =>
    yardActions
        .filter(action => action.yard_id === yardId && !action.end_time)
        .sort((a, b) => new Date(a.start_time) - new Date(b.start_time))
        .map(action => action.id);

export const smoothScroll = (start, end, duration, listRef) => {
    const diff = end - start;
    let startTime;

    const scroll = timestamp => {
        if (!startTime) startTime = timestamp;

        const elapsed = timestamp - startTime;
        const progress = Math.min(elapsed / duration, 1);

        listRef.current.scrollTo(start + diff * progress);

        if (progress < 1) {
            requestAnimationFrame(scroll);
        }
    };

    requestAnimationFrame(scroll);
};

export const getSelectedYardsWithFeedMode = ({ selectedIds, yardActions }) =>
    selectedIds.every(id => {
        const yardActiveAction = yardActions?.find(
            yardAction =>
                yardAction.yard_id === id && !yardAction?.end_time && yardAction.action === actionsValues.FEED_ACTION
        );

        return yardActiveAction;
    });

export const getSelectedActiveModes = ({ selectedIds, yardActions }) =>
    selectedIds.reduce((acc, id) => {
        const yardActiveAction = yardActions?.find(yardAction => yardAction.yard_id === id && !yardAction?.end_time);

        if (yardActiveAction) {
            acc.push(yardActiveAction?.action);
        }
        return acc;
    }, []);

export const calculateWeightDifference = event => {
    const initialWeight = event.payload.initial_frame_weight;
    const afterFillWeight = event.payload.after_fill_frame_weight;
    return afterFillWeight - initialWeight;
};
export const totalWeightDifference = (events = []) => {
    if (!Array.isArray(events)) {
        return '0.0';
    }

    return events
        .reduce((acc, event) => {
            if (event.status !== EVENT_STATUSES.SUCCESSFULLY_FILLED) {
                return acc;
            }
            const weightDiff = calculateWeightDifference(event);
            return acc + (weightDiff * constants.RATIO_GAL_TO_GRAMM || 0);
        }, 0)
        .toFixed(1);
};

const renderFeedCell = value => {
    if (typeof value === 'number') {
        return value.toFixed(1);
    }
    return value || <FontAwesomeIcon icon={faClock} className="icon grey-circle" />;
};

export const generateFeedResultsColumns = cycleCount => {
    const columns = [
        {
            field: 'hive',
            headerName: 'Hive',
            minWidth: 50,
            filterable: false,
            sortable: false,
            flex: 1,
        },
    ];

    let i = 1;
    while (i <= cycleCount) {
        columns.push({
            field: `cycle${i}`,
            headerName: `Cycle ${i}`,
            minWidth: 50,
            filterable: false,
            sortable: false,
            flex: 1,
            renderCell: params => renderFeedCell(params.value),
        });
        i += 1;
    }

    columns.push({
        field: 'total',
        headerName: 'Total',
        minWidth: 50,
        filterable: false,
        sortable: false,
        flex: 1,
        valueGetter: params => renderFeedCell(params.value),
    });

    return columns;
};

export const calculateSyrupLevel = currentBhome =>
    ((Number(currentBhome?.sensors?.syrupLevel) / 100) * constants.SYRUP_GALLONS).toFixed(1);

export const getTimePeriod = time => {
    const now = dayjs();
    const timeOptions = getTimeOptions();
    const timePeriodMapping = {
        [timeOptions[0].value]: { start: now.startOf('week'), end: now.endOf('week') },
        [timeOptions[1].value]: {
            start: now.subtract(1, 'week').startOf('week'),
            end: now.subtract(1, 'week').endOf('week'),
        },
        [timeOptions[2].value]: {
            start: now.subtract(LAST_FIVE_WEEKS, 'week').startOf('week'),
            end: now.endOf('week'),
        },
    };

    return timePeriodMapping[time] || { start: now, end: now };
};

const filterEventsByTimePeriod = (events, timePeriodStart, timePeriodEnd) =>
    events.filter(event => {
        const eventDate = dayjs(event.created_at);
        return eventDate.isBetween(timePeriodStart, timePeriodEnd, null, []);
    });

export const filterYardEventsByTime = (events, selectedTime) => {
    const { start, end } = getTimePeriod(selectedTime);
    const filteredEvents = filterEventsByTimePeriod(events, start, end);

    return filteredEvents.reduce((acc, event) => {
        const bhomeId = event.bhome_id;
        if (!acc[bhomeId]) {
            acc[bhomeId] = [];
        }
        acc[bhomeId].push(event);
        return acc;
    }, {});
};

export const calculateCyclesInTimePeriod = (events, timePeriodStart, timePeriodEnd, autoFeedCycleDays) => {
    const filteredEvents = filterEventsByTimePeriod(events, timePeriodStart, timePeriodEnd);

    if (!filteredEvents.length) return 0;

    const uniqueEventDates = new Set(filteredEvents.map(event => dayjs(event.created_at).format(ISO_DATE_FORMAT)));

    const cycleDates = [];
    let cycleStart = dayjs(timePeriodStart);
    const cycleEnd = dayjs(timePeriodEnd);

    while (cycleStart.isBefore(cycleEnd) || cycleStart.isSame(cycleEnd, 'day')) {
        cycleDates.push(cycleStart.format(ISO_DATE_FORMAT));
        cycleStart = cycleStart.add(autoFeedCycleDays, 'day');
    }

    const allCycleDates = Array.from(new Set([...cycleDates, ...uniqueEventDates])).sort((a, b) =>
        dayjs(a).isBefore(b) ? -1 : 1
    );

    return allCycleDates;
};

export const getIconCounts = rowData => {
    const yellowIconCount = rowData.reduce((acc, row) => acc + row.yellowIconCount, 0);
    const redIconCount = rowData.reduce((acc, row) => acc + row.redIconCount, 0);
    return { yellowIconCount, redIconCount };
};

const getFilledIcon = (cycleWeight, status) => {
    if (status === EVENT_STATUSES.ALREADY_FULL) {
        return {
            icon: (
                <Tooltip content="Feeder was full" position="top center">
                    <FontAwesomeIcon icon={faCircleCheck} className="icon yellow-circle" />
                </Tooltip>
            ),
            isYellowIcon: true,
        };
    }
    if (cycleWeight >= constants.SYRUP_POSITIVE_WEIGHT) {
        return {
            icon: <FontAwesomeIcon icon={faCircleCheck} className="icon green-circle" />,
            isGreenIcon: true,
        };
    }

    const gal = (cycleWeight * constants.RATIO_GAL_TO_GRAMM).toFixed(1);
    return {
        icon: (
            <Tooltip content={`Partially fed (${gal} gal)`} position="top center">
                <FontAwesomeIcon icon={faCircleCheck} className="icon yellow-circle" />
            </Tooltip>
        ),
        isYellowIcon: true,
    };
};

export const generateCycleData = (event, index) => {
    // eslint-disable-next-line camelcase
    const { initial_frame_weight, after_fill_frame_weight, status } = event.payload;
    const isSuccessfullyFilled = status === EVENT_STATUSES.SUCCESSFULLY_FILLED;

    const cycleWeight =
        // eslint-disable-next-line camelcase
        after_fill_frame_weight && initial_frame_weight ? after_fill_frame_weight - initial_frame_weight : 0;
    const { icon, isGreenIcon, isYellowIcon, isRedIcon } =
        isSuccessfullyFilled ||
        (status === EVENT_STATUSES.ALREADY_FULL &&
            !isUndefined(after_fill_frame_weight) &&
            !isUndefined(initial_frame_weight))
            ? getFilledIcon(cycleWeight, status)
            : {
                  icon: <FontAwesomeIcon icon={faCircleXmark} className="icon red-circle" />,
                  isRedIcon: true,
              };

    return {
        [`cycle${index + 1}`]: icon,
        cycleWeight: cycleWeight ?? 0,
        isSuccessfullyFilled,
        isGreenIcon,
        isYellowIcon,
        isRedIcon,
    };
};

export const generateMonthDataRow = eventsGroupedByHives => {
    const totalByHives = {};

    const today = new Date();
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - (today.getDay() + DAYS_IN_WEEK * WEEKS_IN_MONTH));
    startDate.setHours(0, 0, 0, 0);

    Object.keys(eventsGroupedByHives).forEach(hive => {
        let hasUnsuccessfulEvents = false;
        const totalCycleWeight = eventsGroupedByHives[hive].reduce((total, event) => {
            if (event.status !== EVENT_STATUSES.SUCCESSFULLY_FILLED && event.status !== EVENT_STATUSES.ALREADY_FULL) {
                hasUnsuccessfulEvents = true;
            }
            if (new Date(event.created_at) < startDate) {
                return total;
            }
            const cycleData = generateCycleData(event);
            return total + cycleData.cycleWeight;
        }, 0);

        totalByHives[hive] = {
            total: (totalCycleWeight * constants.RATIO_GAL_TO_GRAMM).toFixed(1),
            hasUnsuccessfulEvents,
        };
    });

    return {
        ...totalByHives,
        label: 'Total',
    };
};

export const generateMonthColumns = hives => {
    const columns = [
        {
            field: 'label',
            headerName: '',
            minWidth: 40,
            filterable: false,
            sortable: false,
            flex: 1,
            renderCell: params => {
                const weekNumber = params.row.week;
                return weekNumber;
            },
        },
    ];

    hives.forEach(hive => {
        columns.push({
            field: hive,
            headerName: hive,
            minWidth: 40,
            filterable: false,
            sortable: false,
            flex: 1,
            renderCell: params => {
                const weightFilled = params.row[hive];

                if (!weightFilled) {
                    return '-';
                }

                if (weightFilled.total) {
                    return (
                        <span className={cx({ 'red-info': weightFilled?.hasUnsuccessfulEvents })}>
                            {weightFilled.total}
                        </span>
                    );
                }

                return weightFilled;
            },
        });
    });

    return columns;
};

export const sortObject = object =>
    Object.keys(object)
        .sort((a, b) => a.localeCompare(b))
        .reduce((result, key) => {
            result[key] = object[key];
            return result;
        }, {});

const calculateLastFiveWeeks = () => {
    const today = new Date();
    const weeks = [];

    for (let i = 0; i < LAST_FIVE_WEEKS; i += 1) {
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - (today.getDay() + 7 * i));
        startOfWeek.setHours(0, 0, 0, 0);

        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);
        endOfWeek.setHours(23, 59, 59, 999);
        weeks.push({
            weekNumber: LAST_FIVE_WEEKS - i,
            startDate: startOfWeek,
            endDate: endOfWeek,
        });
    }

    return weeks;
};

export const groupEventsByWeek = sortedEventsGroupedByHives => {
    const weeks = calculateLastFiveWeeks();
    const weeksData = weeks.map(({ weekNumber, startDate, endDate }) => ({
        week: `W${weekNumber}`,
        events: [],
        startDate,
        endDate,
    }));

    Object.keys(sortedEventsGroupedByHives).forEach(hive => {
        const events = sortedEventsGroupedByHives[hive];

        events.forEach(event => {
            const eventDate = new Date(event.created_at);
            const weekNumber = weeks.findIndex(week => eventDate >= week.startDate && eventDate <= week.endDate);
            if (weekNumber !== -1) {
                weeksData[weekNumber].events.push(event);
            }
        });
    });
    return weeksData;
};

export const generateRowDataByWeek = (eventsByWeeks, eventsGroupedByHives) =>
    eventsByWeeks.map((weekObj, index) => {
        const startDate = dayjs(weekObj.startDate).format(DATE_FORMAT_FOR_TOOLTIP);
        const endDate = dayjs(weekObj.endDate).format(DATE_FORMAT_FOR_TOOLTIP);
        const dateRange = `${startDate} - ${endDate}`;
        const row = {
            id: `week-${index + 1}`,
            week: (
                <Tooltip position="top center" content={dateRange}>
                    <span>{weekObj.week}</span>
                </Tooltip>
            ),
        };

        Object.keys(eventsGroupedByHives).forEach(hive => {
            const totalWeightForWeek = weekObj.events
                .filter(event => event.hive === hive)
                .reduce((total, event) => {
                    const filledWeight =
                        event?.payload?.after_fill_frame_weight && event?.payload?.initial_frame_weight
                            ? event.payload.after_fill_frame_weight - event.payload.initial_frame_weight
                            : 0;
                    return total + filledWeight;
                }, 0);

            row[hive] = (totalWeightForWeek * constants.RATIO_GAL_TO_GRAMM).toFixed(1);
        });

        return row;
    });

export const processEventsByWeeks = eventsByWeeks => {
    const eventsGroupedByHives = {};

    eventsByWeeks.forEach(weekObj => {
        const { week, events } = weekObj;

        events.forEach(event => {
            const { hive } = event;
            const filledWeight =
                event.payload.after_fill_frame_weight && event.payload.initial_frame_weight
                    ? event.payload.after_fill_frame_weight - event.payload.initial_frame_weight
                    : 0;

            if (!eventsGroupedByHives[hive]) {
                eventsGroupedByHives[hive] = {};
            }

            if (!eventsGroupedByHives[hive][week]) {
                eventsGroupedByHives[hive][week] = 0;
            }

            eventsGroupedByHives[hive][week] = (filledWeight * constants.RATIO_GAL_TO_GRAMM).toFixed(1);
        });
    });

    return eventsGroupedByHives;
};
