import React from 'react';
import GroupMarker from 'components/reusables/Map/components/GroupMarker';
import YardMarker, { YardMarkerIcon } from 'components/reusables/Map/components/YardMarker';
import BhomeMarker from 'components/reusables/Map/components/BhomeMarker';
import EmptyLocationMarker from 'components/reusables/Map/components/EmptyLocationMarker';
import YardGroupMarker from 'components/reusables/Map/components/YardGroupMarker';
import constants from 'appConstants';

const CLUSTER_ZOOM = 10;

const getIsGroupMarker = (item, map) => item.properties?.cluster || map?.getZoom() <= CLUSTER_ZOOM;

const getCoordinates = item => {
    const [lng, lat] = item.geometry.coordinates;

    return { lat, lng };
};

const getId = item => item.id || item.marker.id;

export const drawMarkers = (clusters, supercluster, map) =>
    clusters?.map(item => {
        const key = item.id || JSON.stringify(item?.marker?.bhome_ids);

        return getIsGroupMarker(item, map) ? (
            <GroupMarker
                type="blured-marker"
                key={key}
                map={map}
                cluster={item}
                supercluster={supercluster}
                {...getCoordinates(item)}
            />
        ) : (
            <BhomeMarker key={key} map={map} location={item.marker} {...getCoordinates(item)} />
        );
    });

export const drawYardClusters = (clusters, supercluster, map) =>
    clusters?.map(item =>
        getIsGroupMarker(item, map) ? (
            <YardGroupMarker
                key={getId(item)}
                cluster={item}
                supercluster={supercluster}
                map={map}
                {...getCoordinates(item)}
            />
        ) : (
            <YardMarkerIcon
                key={item.marker.id}
                map={map}
                yard={item.marker}
                isNameMarker={map?.getZoom() > constants.SPLIT_ZOOM}
                {...getCoordinates(item)}
            />
        )
    );

export const drawLocationMarkers = (clusters, supercluster, map) =>
    clusters?.map(item =>
        getIsGroupMarker(item, map) ? (
            <GroupMarker
                type="empty-marker"
                key={getId(item)}
                cluster={item}
                supercluster={supercluster}
                map={map}
                {...getCoordinates(item)}
            />
        ) : (
            <EmptyLocationMarker
                key={getId(item)}
                id={item?.marker?.id}
                name={item?.marker?.name}
                map={map}
                {...getCoordinates(item)}
            />
        )
    );

const isPolygonInBounds = (polygon, bounds) => {
    const path = polygon.getPath();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < path.getLength(); i++) {
        const point = path.getAt(i);
        if (bounds?.contains(point)) {
            return true; // At least one vertex is within bounds
        }
    }
    return false; // No vertices are within bounds
};

export const drawPolygons = ({ mapRef, ranches, bounds }) => {
    const polygons = [];

    if (!mapRef.current || !ranches?.length) {
        return polygons;
    }

    const currentZoom = mapRef.current?.getZoom();

    ranches.forEach(item => {
        item.polygons?.forEach(poly => {
            const polygon = new window.google.maps.Polygon({
                paths: poly,
                strokeColor: '#b0d000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: 'transparent',
            });
            const isInsideBounds = isPolygonInBounds(polygon, bounds);

            if (!isInsideBounds || currentZoom < constants.SPLIT_ZOOM) {
                return;
            }
            polygons.push(polygon);
            polygon.setMap(mapRef.current);
        });
    });

    return polygons;
};

export const drawWorkspaceYardRanchClusters = ({
    clusters,
    supercluster,
    handleYardClick,
    map,
    selectedIds,
    handleCheckboxClick,
    hoveredYards,
    clickedYard,
    mapContainerRef,
    setHoveredYard,
    hoveredYard,
}) =>
    clusters?.map(item => {
        const isSelected = selectedIds?.includes(item?.marker?.id);
        const isHovered = hoveredYards?.includes(item?.marker?.id);
        const isSingleHovered = hoveredYard === item?.marker?.id;
        const id = getId(item);

        return getIsGroupMarker(item, map) ? (
            <YardGroupMarker key={id} cluster={item} supercluster={supercluster} map={map} {...getCoordinates(item)} />
        ) : (
            <YardMarker
                key={id}
                map={map}
                yard={item?.marker}
                isSelected={isSelected}
                onClick={handleYardClick}
                handleCheckboxClick={handleCheckboxClick}
                isHovered={isHovered || isSingleHovered}
                selectedIds={selectedIds}
                clickedYard={clickedYard}
                mapContainerRef={mapContainerRef}
                setHoveredYard={setHoveredYard}
                isRanch={item?.marker?.isRanch}
                {...getCoordinates(item)}
            />
        );
    });
