import { createSelector } from 'reselect';
import constants from 'appConstants';
import { groupBy, max, min } from 'lodash-es';
import { averagesValues, mapData, filterYards, sortYards, actionsValues } from '../utils';
import { getBhomes, getYards } from '../../Dashboard/selectors';

export const getWorkspaceData = state => state.workspace.data;
export const getIsWorkspaceFetched = state => state.workspace.isFetched;
export const getWorkspaceSearch = state => state.workspace.search;
export const getWorkspaceFilter = state => state.workspace.filter;
export const getWorkspaceSort = state => state.workspace.sort;
export const getYardActions = state => state.workspace.yardActions;
export const getInspectedYard = state => state.workspace.inspectedYard;
export const getYardEvents = state => state.workspace.yardEvents;
export const getWorkspaceRanches = state => state.workspace.workspaceRanches;

export const getPreparedWorkspaceData = data => {
    const groupedByDate = groupBy(data, 'createdAt');
    const todaysDataKey = max(Object.keys(groupedByDate));
    const yesterdaysDataKey = min(Object.keys(groupedByDate));
    const todaysGroupedData = groupBy(groupedByDate[todaysDataKey], 'yardId');
    const todaysRanchesGroupedData = groupBy(groupedByDate[todaysDataKey], 'ranchId');
    const yesterdaysGroupedData = groupBy(groupedByDate[yesterdaysDataKey], 'yardId');
    const yesterdaysRanchesGroupedData = groupBy(groupedByDate[yesterdaysDataKey], 'ranchId');

    const yardsData = Object.keys(todaysGroupedData).reduce(mapData(todaysGroupedData, yesterdaysGroupedData), []);
    const ranchesData = Object.keys(todaysRanchesGroupedData).reduce(
        mapData(todaysRanchesGroupedData, yesterdaysRanchesGroupedData, true),
        []
    );

    return [...yardsData, ...ranchesData];
};

const getGroupedWorkspaceData = (data, yards, ranches, bhomes, yardActions, search, filter, sort) => {
    const yardsMap = new Map(yards.map(yard => [yard.id, yard]));
    const ranchesMap = new Map(ranches.map(ranch => [ranch.id, ranch]));

    let combinedData = data.map(item => {
        if (item.isRanch) {
            const ranch = ranchesMap.get(item.id);
            const ranchActionsFiltered = yardActions.filter(yardActions => yardActions.ranch_id === item.id);
            const ranchBhomes = ranch?.locations?.reduce((acc, ranchItem) => {
                if (!ranchItem?.bhomes?.length) {
                    return acc;
                }
                acc.push(...ranchItem.bhomes);
                return acc;
            }, []);
            const nonOperationalBhomesAmount = ranchBhomes?.reduce((acc, id) => {
                const bhomesData = bhomes.find(bhome => bhome.id === id);
                if (bhomesData?.operational_state === constants.BHOME_OPERATIONAL_STATES.NON_OPERATIONAL) {
                    return acc + 1;
                }
                return acc;
            }, 0);
            return {
                ...item,
                name: ranch?.name ?? item.name,
                lat: ranch?.lat,
                lng: ranch?.lng,
                yardActions: ranchActionsFiltered,
                nonOperationalBhomesAmount,
            };
        }
        const yard = yardsMap.get(item.id);
        const yardActionsFiltered = yardActions.filter(yardActions => yardActions.yard_id === item.id);
        const nonOperationalBhomesAmount = yard?.bhomes.reduce((acc, id) => {
            const bhomesData = bhomes.find(bhome => bhome.id === id);
            if (bhomesData?.operational_state === constants.BHOME_OPERATIONAL_STATES.NON_OPERATIONAL) {
                return acc + 1;
            }
            return acc;
        }, 0);

        return {
            ...item,
            name: yard?.name ?? item.name,
            lat: yard?.lat,
            lng: yard?.lng,
            yardActions: yardActionsFiltered,
            nonOperationalBhomesAmount,
        };
    });

    if (!filter.activeOnly) {
        combinedData = [
            ...combinedData,
            ...yards.reduce((acc, yard) => {
                if (data.some(item => item.id === yard.id)) {
                    return acc;
                }

                return [
                    ...acc,
                    {
                        id: yard.id,
                        name: yard.name,
                        lat: yard.lat,
                        lng: yard.lng,
                    },
                ];
            }, []),
            ...ranches.reduce((acc, ranch) => {
                if (data.some(item => item.id === ranch.id)) {
                    return acc;
                }

                return [
                    ...acc,
                    {
                        id: ranch.id,
                        name: ranch.name,
                        lat: ranch.lat,
                        lng: ranch.lng,
                        isRanch: true,
                    },
                ];
            }, []),
        ];
    }

    return combinedData.filter(filterYards(filter)).toSorted((a, b) => sortYards(a, b, sort));
};

export const getYardGroupedData = createSelector(
    [
        getWorkspaceData,
        getYards,
        getWorkspaceRanches,
        getBhomes,
        getYardActions,
        getWorkspaceSearch,
        getWorkspaceFilter,
        getWorkspaceSort,
    ],
    getGroupedWorkspaceData
);

export const getFilterNumber = filter => {
    const keysToCheck = [
        'regions',
        'syrupTank',
        'larvae',
        averagesValues.EMPTY_FRAMES_AVERAGE,
        averagesValues.BEE_FRAMES_AVERAGE,
        averagesValues.HONEY_FRAMES_AVERAGE,
        averagesValues.BROOD_FRAMES_AVERAGE,
        actionsValues.FEED_ACTION,
        actionsValues.VISIT_ACTION,
        actionsValues.TRANSPORT_ACTION,
    ];

    return Object.keys(filter).reduce((acc, key) => {
        if (key === 'regions' && filter[key]?.length) {
            return acc + filter[key].length;
        }

        if (['syrupTank', 'larvae'].includes(key) && (filter[key].min > 0 || filter[key].max < 100)) {
            return acc + 1;
        }

        if (['activeOnly'].includes(key) && filter[key]) {
            return acc + 1;
        }

        if (keysToCheck.slice(5).includes(key) && filter[key].value) {
            return acc + 1;
        }

        if (
            (key === actionsValues.VISIT_ACTION ||
                key === actionsValues.FEED_ACTION ||
                key === actionsValues.TRANSPORT_ACTION) &&
            (filter[key].activeNow || filter[key].pastWeek || filter[key].moreThanAWeek)
        ) {
            return acc + 1;
        }

        return acc;
    }, 0);
};
