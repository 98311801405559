import { isEmpty } from 'lodash-es';
import {
    FETCH_WORKSPACE_DATA,
    FETCH_CREATE_YARD_ACTION,
    FETCH_UPDATE_YARD_ACTION,
    FETCH_GET_YARD_ACTION,
    CHANGE_YARD_FILTER,
    CLEAR_YARD_FILTER,
    CHANGE_YARD_SORT,
    SET_YARD_TO_INSPECT,
    FETCH_GET_YARD_EVENTS_BY_BHOME_IDS,
} from '../actionTypes';
import { getPreparedWorkspaceData } from '../selectors';
import { actionsValues, averagesValues, FILTER_AVERAGE_OPTIONS, SORT_WORKSPACE_OPTIONS } from '../utils';
import { FETCH_DELETE_YARD } from '../../BeeHome/actionTypes';

const defaultFilterState = {
    activeOnly: true,
    syrupTank: {
        min: 0,
        max: 100,
    },
    larvae: {
        min: 0,
        max: 100,
    },
    [averagesValues.EMPTY_FRAMES_AVERAGE]: {
        option: FILTER_AVERAGE_OPTIONS[0].value,
        value: '',
        trend: null,
    },
    [averagesValues.BEE_FRAMES_AVERAGE]: {
        option: FILTER_AVERAGE_OPTIONS[0].value,
        value: '',
        trend: null,
    },
    [averagesValues.HONEY_FRAMES_AVERAGE]: {
        option: FILTER_AVERAGE_OPTIONS[0].value,
        value: '',
        trend: null,
    },
    [averagesValues.BROOD_FRAMES_AVERAGE]: {
        option: FILTER_AVERAGE_OPTIONS[0].value,
        value: '',
        trend: null,
    },
    [actionsValues.TRANSPORT_ACTION]: {
        activeNow: false,
    },
    [actionsValues.VISIT_ACTION]: {
        activeNow: false,
        pastWeek: false,
        moreThanAWeek: false,
    },
    [actionsValues.FEED_ACTION]: {
        activeNow: false,
        pastWeek: false,
        moreThanAWeek: false,
    },
    regions: [],
};

const initialState = {
    data: [],
    workspaceRanches: [],
    isFetched: false,
    filter: defaultFilterState,
    sort: SORT_WORKSPACE_OPTIONS[0].value,
    yardActions: [],
    inspectedYard: null,
    yardEvents: [],
};

const workspace = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_WORKSPACE_DATA.success:
            return {
                ...state,
                data: getPreparedWorkspaceData(action.data.result),
                workspaceRanches: action.data.ranches,
                isFetched: true,
            };

        case FETCH_DELETE_YARD.success:
            return {
                ...state,
                data: state.data.filter(yard => yard.id !== action.data.yard.id),
            };
        case CHANGE_YARD_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.filter,
                },
            };
        case CLEAR_YARD_FILTER:
            return {
                ...state,
                filter: defaultFilterState,
            };
        case CHANGE_YARD_SORT:
            return {
                ...state,
                sort: action.sort,
            };
        case FETCH_UPDATE_YARD_ACTION.success:
        case FETCH_CREATE_YARD_ACTION.success:
            return {
                ...state,
                yardActions: [...state.yardActions, ...action.data.yardActions],
            };
        case FETCH_GET_YARD_ACTION.success:
            return {
                ...state,
                yardActions: action.data.yardActions,
            };
        case SET_YARD_TO_INSPECT: {
            const data = isEmpty(action.yard) ? { inspectedYard: {} } : {};
            return {
                ...state,
                ...data,
                inspectedYard: action.yard,
            };
        }
        case FETCH_GET_YARD_EVENTS_BY_BHOME_IDS.success:
            return {
                ...state,
                yardEvents: action.data.yardEvents,
            };
        default:
            return state;
    }
};

export default workspace;
